import React, { useEffect } from 'react';
import './App.css';
import { HashRouter, NavLink, Route, Routes } from 'react-router-dom';
import { Home } from './components/Home/Home'
import { Nosotros } from './components/Nosotros/Nosotros'
import { Sedes } from './components/Sedes/Sedes';
import { Servicios } from './components/Servicios/Servicios';
import { Contacto } from './components/Contacto/Contacto';
import { Formatos } from './components/Formatos/Formatos';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init();
    window.addEventListener('scroll', handleScroll);
    window.scrollTo(0, 0);
  }, []);

  function handleScroll() {

    const menuCont = document.querySelector('.menuCont');
    const nav = document.querySelector('nav');

    if (!!menuCont) {

      if (window.scrollY > 150) {
        menuCont.style.position = 'fixed';
        menuCont.style.height = '9vh';
        menuCont.style.top = '0';
      } else {
        menuCont.style.position = 'relative';
        menuCont.style.top = '4vh';
      }
    } else {
      if (window.scrollY > 150) {
        nav.style.position = 'fixed';
        nav.style.top = '0';
      } else {
        nav.style.position = 'relative';
      }
    }
  }

  return (
    <div className="App">

      <HashRouter>

        {(window.innerWidth > 440) &&
          <header className="App-header">
            <div className='infoCont'>
              <div className='logo' data-aos="fade-up" data-aos-duration="3000" ></div>
              {/* <div className='logo'></div> */}
              <div className='contInfo' data-aos="fade-up" data-aos-duration="3000">
                <div className='locationIcon'></div>
                <div className='locationText'>
                  <span style={{ cursor: 'text' }}>Cra 5ta A No. 2 – 32 Oficina 401</span>
                  <span style={{ cursor: 'text' }}>Buenaventura, Colombia</span>
                </div>
                <div className='telIcon'></div>
                <div className='telText'>
                  <span style={{ cursor: 'text' }}>+57 (602) 2442507</span>
                  <span style={{ cursor: 'text' }}>+57 (317) 549-2739</span>
                </div>
                <div className='facebook'></div>
                <div className='instagram'></div>
                <div className='twitter'></div>
              </div>
            </div>

            <nav className='menuCont'>
              <div className='opInicio'> <NavLink to={'/'}>Inicio</NavLink > </div>
              <div className='opNosotros'> <NavLink to={'/nosotros'}>Nosotros</NavLink > </div>
              <div className='opServicios'> <NavLink to={'/sedes'}>Sedes</NavLink> </div>
              <div className='opContacto'> <NavLink to={'/servicios'}>Servicios</NavLink> </div>
              <div className='opContacto'> <NavLink to={'/contacto'}>Contacto</NavLink> </div>
              <div className='opContacto'> <NavLink to={'/formatos'}>Formatos</NavLink> </div>
            </nav>


          </header>
        }
        {(window.innerWidth <= 440) &&
          <nav role="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <li> <NavLink to={'/'}>Inicio</NavLink> </li>
                <li> <NavLink to={'/nosotros'}>Nosotros</NavLink > </li>
                <li> <NavLink to={'/sedes'}>Sedes</NavLink> </li>
                <li> <NavLink to={'/servicios'}>Servicios</NavLink> </li>
                <li> <NavLink to={'/contacto'}>Contacto</NavLink> </li>
                <li> <NavLink to={'/formatos'}>Formatos</NavLink> </li>
              </ul>
            </div>
            <div className='logoMobile'></div>
          </nav>
        }

        <Routes>
          <Route path='/' element={<Home></Home>} />
          <Route path='/nosotros' element={<Nosotros></Nosotros>} />
          <Route path='/sedes' element={<Sedes></Sedes>} />
          <Route path='/servicios' element={<Servicios></Servicios>} />
          <Route path='/contacto' element={<Contacto></Contacto>} />
          <Route path='/formatos' element={<Formatos></Formatos>} />
          <Route path='*' element={<h1>404 Not found</h1>} />
        </Routes>

        <footer>
          <div className="footerCont">
            <div className="produInfo">
              <div className="row">Cra 5ta A No. 2 – 32 Oficina 401 Barrio Centro / Buenaventura - Colombia, Sur América</div>
              <div className="row">COMEXPAL - <span style={{ color: "rgb(0, 0, 42)" }}> https://comexpaladuanas.com</span></div>
              <div className="row">Teléfonos: +57 (602) 2442507</div>
              <div className="row">Celular: +57 (317) 549-2739</div>
              <div className="row">Email: administracion@comexpalsas.com - comercial@comexpalsas.com</div>
              <div className="creativeC">© 2023. All Rights Reserved. Privacy policy</div>
            </div>
          </div>
        </footer>


      </HashRouter>
    </div>
  );
}

export default App;
